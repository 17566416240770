/**
 * Aside menu config
 *
 * To more info about config of menu items go to knowledgebase
 */
import store from "@/core/services/store";

export default {
// Root level also must be configured as menu item (must have submenu key, other ignored)
  submenu: {
    // Root (not only root) menu items text delimiter
    my_forms: {
        active: true,
        to: {
            name: "exam.user.forms.list",
            params: {}
        },
        text: {
            code: "exam.exam_pass.title",
            default: "Questionnaires for rating"
        },
        b_icon: "journal",
    },
    my_result: {
        active: true,
        to: {
            name: "exam.results",
            params: {}
        },
        text: {
            code: "exam.exam_menu.my_results",
            default: "My results"
        },
        b_icon: "star",
    },
/*     head: {
        active: function(storage_getters) {
            const user = storage_getters.currentUser;
            return user ? user.is_head : false;
        },
        text: {
            code: "exam.exam_menu.director",
            default: "Director"
        },
        b_icon: "folder",
        submenu: {
        }
    }, */
    administration: {
        active: function() {
            const user = store.getters.currentUser;
            return user ? user.is_admin : false;
        },
        text: {
            code: "exam.exam_menu.administrator",
            default: "Administrator"
        },
        b_icon: "shield-fill-check",
        submenu: {
            create_test:{
                active: true,
                to: {
                  name: "exam.new",
                  params: {}
                },
                text: {
                  code: "exam.create",
                  default: "Create exam"
                },
                b_icon: "journal-plus"
            },
            generation: {
                active: true,
                to: {
                    name: "exams.launch",
                    params: {},
                },
                text: {
                    code: "exam.launch_exam",
                    default: "Launch exam"
                },
                b_icon: "arrow-repeat",
            },
             all_exam: {
                active: true,
                to: {
                    name: "list",
                    params: {},
                },
                text: {
                    code: "exam.exams_list",
                    default: "All tests"
                },
                b_icon: "card-checklist",
            },
            all_filled_exams: {
                active: true,
                to: {
                    name: "completed.exams",
                    params: {},
                },
                text: {
                    code: "exam.exams_filled_forms",
                    default: "All completed tests"
                },
                b_icon: "journal",
            },
        }
    },
    help: {
        active: true,
        to: {
            name: "help",
            params: {},
        },
        text: {
            code: "exam.exam_menu.help",
            default: 'Help',
        },
        b_icon: "question-circle",
    }
  }
};
