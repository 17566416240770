import Vue from "vue";

const user_forms = {
    namespaced: true,
    state: {
        formsUserLoaded: false,
        formsUserList: {},
        filledForms: [],
        paginationFilledForms: {},
        resultsUser: [],
    },
    getters: {
        formsUserLoaded: state => state.formsUserLoaded,
        formsUserList: state => state.formsUserList,
        filledForms: state => state.filledForms,
        getFormById: state => formId => state.formsUserList[formId],
        getFilledFormById: state => formId => state.filledForms.find((form => form.id === Number(formId))),
        paginationFilledForms: state => state.paginationFilledForms,
        resultsUser: state => state.resultsUser,
    },
    mutations: {
        SET_FORM_USER(state, { id, data }) {
            Vue.set(state.formsUserList, id, data);
        },
        SET_FILLED_FORM(state, { id, data }) {
            let formIndex = state.filledForms.findIndex(form => form.id === id);

            if (formIndex > -1)
                Vue.set(state.filledForms, formIndex, data);
            else
                state.filledForms.push(data);
        },
        SET_FILLED_FORMS(state, { data, pagination }) {
            Vue.set(state, "filledForms", data);
            Vue.set(state, "paginationFilledForms", pagination);
        },
        SET_RESULTS_FORMS(state, data){
            Vue.set(state, "resultsUser", data);
        },
        SET_FORMS_USER_LOADED(state, loaded) {
            Vue.set(state, "formsUserLoaded", loaded);
        },
        DELETE_FORM_USER(state, id) {
            Vue.delete(state.formsUserList, id);
        }
    },
    actions: {
        loadFormsUser({commit}, userId = null) {
            commit("SET_FORMS_USER_LOADED", false);
            return new Promise((resolve, reject) => {
                this.$app.$api.exam.user_forms
                .list(userId)
                .then(response => {
                    response.data.data.forEach(form => {
                        commit("SET_FORM_USER", { id: form.id, data: form });
                    });
                    commit("SET_FORMS_USER_LOADED", true);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
            });
        },
        loadForm(context, formId) {
            return this.$app.$api.exam.user_forms
                .show(formId)
                .then(response => {
                    const form = response.data.data;
                    context.commit("SET_FORM_USER", { id: form.id, data: form });
                });
        },
        loadFilledForm(context, formId) {
            return this.$app.$api.exam.user_forms
                .filledForm(formId)
                .then(response => {
                    const form = response.data.data;
                    context.commit("SET_FILLED_FORM", { id: form.id, data: form });
                });
        },
        fillQuestion(context, payload) {
            this.$app.$api.exam.user_forms.fillQuestion({
                evaluationId: payload.evaluationId,
                data: {
                    answer_value: payload.answerValue
                }
            });
        },
        fillForm(context, formId) {
            this.$app.$api.exam.user_forms.fillForm(formId)
                .then(() => context.commit("DELETE_FORM_USER", formId));
        },
        searchExamFilledForms(context, payload) {
            return this.$app.$api.exam.forms.filledList(payload)
                .then((response) => {
                    let pagination = {};
                    for (let index in response.data.meta) {
                        if (index === "current_page" || index === "per_page" || index === "total")
                            pagination[index] = response.data.meta[index];
                    }
                    context.commit("SET_FILLED_FORMS", { data: response.data.data, pagination: pagination });
                });
        },
        resultsExamUser(context, payload) {
            let request = this.$app.$api.exam.user_forms.filledList(payload);
            request.then((response) => {
                context.commit("SET_RESULTS_FORMS", { data: response.data.data });
            });
            return request;
        }
    }
};

export default user_forms;