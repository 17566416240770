import Vue from "vue";

const getters = {
  listOfExams: state => state.listExams,
  paginationTemplates: state => state.examsTemplatesPagination,
  getExamById: state => exam_id => state.listExams[exam_id],
  getPublishedTemplates: state => state.publishedTemplates
};
const mutations = {
  /* Answers */
  SET_ANSWERS_TO_QUESTION(state, { exam_id, question_order, answers }) {
    let sorted_answers = answers.sort(
      (a, b) => a.order > b.order ? 1 : -1
    );
    Vue.set(
      state.listExams[exam_id].questions[question_order],
      "answers",
      sorted_answers
    );
  },
  ADD_ANSWER_TO_QUESTION(state, { answerOption, question }) {
    let existing_answer_index = state.listExams[question.exam_template_id].questions[question.order].answers.findIndex(element => element.order === answerOption.order);
    if (existing_answer_index !== -1)
      Vue.set(
        state.listExams[question.exam_template_id].questions[question.order].answers,
        existing_answer_index,
        answerOption
      );
    else
      state.listExams[question.exam_template_id].questions[question.order].answers.push(answerOption);
  },
  DELETE_ANSWER_FROM_MODULE(state, { examId, questionOrder, answerOrder }) {
    let foundQuestion = state.listExams[examId].questions[questionOrder];
    let answers = foundQuestion.answers;
    let indexSearch = answers.findIndex(element => element.order === answerOrder);
    answers.splice(indexSearch, 1);
    mutations.SET_ANSWERS_TO_QUESTION(state, { examId, questionOrder, answers });
  },
  
  /* Questions */
  ADD_QUESTION_TO_LIST(state, question) {
    const { id, order, exam_template_id } = question;
    Vue.set(state.listQuestions, question.id, {
      id,
      order,
      exam_template_id
    });
  },
  ADD_QUESTION_TO_EXAM(state, { exam_id, question }) {
    if (!question.answers)
      question["answers"] = (state.listExams[exam_id].questions[question.order].answers).sort((a, b) => (a.order - b.order));
    else question.answers.sort((a, b) => (a.order - b.order));
    Vue.set(state.listExams[exam_id]["questions"], question.order, question);
  },
  ADD_QUESTIONS_TO_EXAM(state, { exam_id, questions }) {
    Vue.set(state.listExams[exam_id], "questions", {});
    questions.forEach(question => {
      mutations.ADD_QUESTION_TO_LIST(state, question);
      mutations.ADD_QUESTION_TO_EXAM(state, {
        exam_id: exam_id,
        question: question
      });
    });
  },
  DELETE_QUESTION_FROM_TEMPLATE(state, data) {
    // We go here only when question has no id => there are no questions after it
    Vue.delete(state.listExams[data.examId].questions, data.questionOrder);
  },
  DELETE_QUESTION_FROM_LIST(state, question_id) {
    Vue.delete(state.listQuestions, question_id);
  },
  CLEAR_QUESTION_ANSWERS(state, { examId, questionOrder }) {
    state.listExams[examId].questions[questionOrder].answers =
        state.listExams[examId].questions[questionOrder].answers.filter((answer) => {
          return answer.correct === null;
        });
  },
  
  /* Exam Templates */
  ADD_EXAM_TO_LIST(state, exam) {
    const questions = exam.questions;
    exam.questions = {};
    exam.is_full = true;
    if (state.listExams[exam.id]) {
      Object.keys(exam).forEach(key => {
        Vue.set(state.listExams[exam.id], key, exam[key]);
      });
    } else {
      Vue.set(state.listExams, exam.id, exam);
    }
    if (questions)
      mutations.ADD_QUESTIONS_TO_EXAM(state, {
        exam_id: exam.id,
        questions: questions,
      });
  },
  UPDATE_EXAM_DESCRIPTION(state, exam) {
    if (state.listExams[exam.id]) {
      Object.keys(exam).forEach(key => {
        Vue.set(state.listExams[exam.id], key, exam[key]);
      });
    } else {
      Vue.set(state.listExams, exam.id, exam);
    }
    if (!state.listExams[exam.id].questions)
      Vue.set(state.listExams[exam.id], "questions", {});
  },
  SET_TEMPLATES_LIST(state, { data, pagination }) {
    data.forEach(examTemplate => {
      examTemplate["questions"] = {};
      examTemplate["is_full"] = false;
      Vue.set(state.listExams, examTemplate.id, examTemplate);
    });
    //Vue.set(state, "listExams", data);
    Vue.set(state, "examsTemplatesPagination", pagination);
  },
  SET_PUBLISHED_TEMPLATES(state, templates) {
    Vue.set(state, "publishedTemplates", templates);
  }
};

const templates = {
  namespaced: true,
  state: {
    listExams: {
      /* Object with all stored exam templates keyed by id
       *   <exam_id>: {
       *     ...
       *     questions: { Object with all exam questions keyed by order
       *       <question_order>: {
       *         ...
       *         answers: [ Array with all questions answers
       *           {one answer},
       *           {another answer}
       *         ]
       *       },
       *       <question_order>: {another question},
       *     }
       *   },
       *   <exam_id>: {another exam},
       * */
    },
    examsTemplatesPagination: {},
    listQuestions: {
      /* Object with all stored questions keyed by id
       *    <question_id>: { one question },
       *    <question_id>: {another question},
       * */
    },
    publishedTemplates: []
  },
  getters: getters,
  mutations: mutations,
  actions: {
    /* Answers */
    saveExamAnswer(context, answerOption) {
      if (!answerOption.exam_question_id || answerOption.text < 1) return;
      let request = answerOption.id ?
        this.$app.$api.exam.answers.update({
          id: answerOption.id,
          data: answerOption
        }) : this.$app.$api.exam.answers.create(answerOption);
      request.then(response => {
        context.commit("ADD_ANSWER_TO_QUESTION", {
          answerOption: response.data.data,
          question:
            context.state.listQuestions[response.data.data.exam_question_id]
        });
      });
      return request;
    },
    updateCorrectAnswer(context, { answerOption, needUncheck }) {
      if (answerOption.id) {
        let request = this.$app.$api.exam.answers.update({
          id: answerOption.id,
          data: answerOption
        });
        request.then(response => {
          let question = context.state.listQuestions[response.data.data.exam_question_id];
          context.commit("ADD_ANSWER_TO_QUESTION", {
            answerOption: response.data.data,
            question: question
          });
        
          if (needUncheck) {
            let allAnswerOptions = context.state.listExams[question.exam_template_id].questions[question.order].answers;
            allAnswerOptions
              .filter(function(item) {
                return item.id !== response.data.data.id;
              })
              .forEach(function(allAnswerOption) {
                allAnswerOption.correct = false;
              });
          }
        });
        return request;
      } else return;
    },
    deleteAnswer(context, data) {
      let idAnswers = data.answerId;
      if (idAnswers) {
        let request = this.$app.$api.exam.answers.delete(idAnswers);
        request.then((response) => {
          context.commit("SET_ANSWERS_TO_QUESTION", {
            exam_id: data.examId,
            question_order: data.questionOrder,
            answers: response.data.data
          });
        });
        return request;
      } else {
        context.commit("DELETE_ANSWER_FROM_MODULE", data);
        return;
      }
    },
    saveCustomExamAnswer(context, answerOption) {
      if (!answerOption.exam_question_id) return;
      return this.$app.$api.exam.answers.saveCustomAnswerOption(answerOption)
          .then(response => {
            context.commit("ADD_ANSWER_TO_QUESTION", {
              answerOption: response.data.data,
              question: context.state.listQuestions[response.data.data.exam_question_id]
        });
      });
    },
    
    /* Questions */
    saveExamQuestion(context, question) {
      let request = question.id ?
        this.$app.$api.exam.questions.update({
          id: question.id,
          data: question
        }) : this.$app.$api.exam.questions.create(question);
      request.then(response => {
        context.commit("ADD_QUESTION_TO_EXAM", {
          exam_id: response.data.data.exam_template_id,
          question: response.data.data
        });
        context.commit("ADD_QUESTION_TO_LIST", response.data.data);
      });
      return request;
    },
    deleteQuestion(context, data) {
      let idQuestion = data.questionId;
      let examId = data.examId;
      if (idQuestion) {
        let request = this.$app.$api.exam.questions.delete(idQuestion);
        request.then((response) => {
          context.commit("ADD_QUESTIONS_TO_EXAM", {
            exam_id: examId,
            questions: response.data.data,
          });
          context.commit("DELETE_QUESTION_FROM_LIST", data.questionId);
        });
        return request;
      } else {
        context.commit("DELETE_QUESTION_FROM_TEMPLATE", data);
        return;
      }
    },
    destroyQuestionAnswers(context, question) {
      let request;
      if (question.answersToDestroy.length > 0)
        request = this.$app.$api.exam.answers.massDelete({ answers: question.answersToDestroy }).then(() => {
          context.commit("CLEAR_QUESTION_ANSWERS", { examId: question.examId, questionOrder: question.order });
        });

      return request;
    },

    /* Exam Template */
    loadExam(context, examId) {
      let request = this.$app.$api.exam.template.show(examId);
      request.then(response => {
        context.commit("ADD_EXAM_TO_LIST", response.data.data);
      });
      return request;
    },
    createExamTemplate(context, exam) {
      let request = this.$app.$api.exam.template.create(exam);
      request.then(response => {
        context.commit("UPDATE_EXAM_DESCRIPTION", response.data.data);
      });
      return request;
    },
    updateExamTemplate(context, exam) {
      let request = this.$app.$api.exam.template.edit({
        id: exam.id,
        data: exam
      });
      request.then(response => {
        context.commit("UPDATE_EXAM_DESCRIPTION", response.data.data);
      });
      return request;
    },
    searchExamTemplates(context, payload) {
      return this.$app.$api.exam.template
        .list(payload.filters, {
          page: payload.page,
          per_page: payload.per_page,
          query: payload.query
        })
        .then(response => {
        let pagination = {};
        for (let index in response.data.meta) {
          if (
            index === "current_page" ||
            index === "per_page" ||
            index === "total"
          )
            pagination[index] = response.data.meta[index];
        }
        context.commit("SET_TEMPLATES_LIST", {
          data: response.data.data,
          pagination: pagination
        });
      });
    },
    getPublishedTemplates(context, payload) {
      return this.$app.$api.exam.template
        .list(payload.filters, {
          page: payload.page,
          per_page: payload.per_page,
          query: payload.query
        })
        .then(response => {
        context.commit("SET_PUBLISHED_TEMPLATES", response.data.data);
      });
    }
  }
};

export default templates;
