const exams = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        launchExam(context, exam) {
            let request = this.$app.$api.exam.launch(exam);
            return request;
        },
        exportToExcelForms(context, data) {
            return new Promise((resolve, reject) => {
                this.$app.$api.exam.forms.export(data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
};

export default exams;
