export default {
    exam: {
        template: 'Exam',
        continue: 'Continue',
        back: 'Back',
        title: 'Title',
        title_placeholder: 'Enter title',
        description: 'Description',
        description_placeholder: 'Enter description',
        correct_option: 'Correct option',
        members: 'Members',
        minimum_requirements: 'To start testing, you need to select a minimum one member or department',
        date_start: 'Launch date',
        date_finish: 'Finish date',
        date_from: 'from',
        date_to: 'to',
        remind: 'Remind to users',
        before_finish_date: 'the day before the end date',
        custom_remind_date: 'custom remind date',
        remind_date: 'Reminder date',
        start_exam_notify: 'Notify about the start exam',
        launch: 'Launch exam',
        launch_exam: 'Launch exam',
        launching: 'set exam to users',
        no_published_templates: 'No published',
        exams_list: "Tests",
        create: "Create a test",
        creating: "Creating a test",
        title_main: "Main",
        error: "Error",
        status_title: "Status",
        search_placeholder: "Enter the test name",
        save_exam: "Exam saved",
        exams_filled_forms: "All exams results",
        myresults: "My results",
        tabs: {
            description: 'Description',
            structure: 'structure'
        },
        structure: {
            add: {
                question: 'Add question',
                answer_option: 'Add answer option'
            },
            mode: {
                simple: "One answer option",
                multi: "Multiple answer options",
                custom: "Free form response"
            },
            placeholder: {
                question: 'Question text',
                answer_option: 'Answer option text',
                user_answer: 'User\'s answer'
            },
            errors: {
                cant_add_question: "Fill previous question",
                cant_add_answer_no_question: "Fill question",
                cant_add_answer: "Fill previous answer",
                need_one_correct_answer: "There cannot be more than one correct answer for this type of question"
            }
        },
        exam_pass: {
            title: "Passing tests",
            no_exam: "There are no tests in this section yet",
            title_deadline: " Deadline:",
            finish: "Finish",
            finish_later: "Finish later",
            no_results: "There are no test results yet",
            date_exam:"Test date",
            result_percent: "Percentage of correct answers"
        },
        exam_menu: {
            director: "Director",
            administrator: "Administrator",
            my_results: "My results",
            help: "Help"
        },
        table_headers: {
            created_at: "Date of creation",
            name: "Name of the test",
            filled_date: "Completion date",
            exam_taker: "Exam taker",
            exam_template: "Exam",
            result: "Result"
        },
        exam_btn:{
            edit: "Edit",
            save: "Save"
        },
        statuses: {
            draft: "Draft",
            published: "Published"
        },
        excel: {
            download: "Excel",
            fail_download: "Fail export to excel"
        }
    }
};