/**
 * Routes of service
 *
 * All routes must be use layout component on first level (with path "/")
 * Root of service index component must have redirect to service index page (it may any name - not only index)
 */

export default [
    {
        path: "/",
        redirect: { name: "exam.index" },
        name: "root",
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "/",
                components: {
                    default: () => import("./layouts/ExamLayout"),
                    "title-actions": () => import("./components/ActionsButton.vue"),
                },
                children: [
                    {
                        path: "/",
                        name: "exam.index",
                        component: () => import("./views/ExamUserFormsList.vue"),
                    },
                    {
                        path: "/exam/results",
                        name: "exam.results",
                        component: () => import("./views/ExamMyResults.vue"),
                    },
                    {
                        path: "/exam/new",
                        name: "exam.new",
                        component: () => import("./views/ExamEdit.vue")
                    },
                    {
                        path: "/exam/edit/:id/:tab",
                        name: "exam.edit",
                        component: () => import("./views/ExamEdit.vue"),
                        props: true
                    },
                    {
                        path: "/exam/show/:id/:tab",
                        name: "exam.show",
                        component: () => import("./views/ExamEdit.vue"),
                        props: true
                    },
                    {
                        path: "/list",
                        name: "list",
                        component: () => import("./views/ExamsList.vue")
                    },
                    {
                        path: "/exams-launch",
                        name: "exams.launch",
                        component: () => import("./views/LaunchExam.vue")
                    },
                    {
                        path: "/forms",
                        name: "exam.user.forms.list",
                        component: () => import("./views/ExamUserFormsList.vue"),
                        
                    },
                    {
                        path: "/exam-form/:id",
                        component: () => import("@hihub/vue-hihub-exam-components/src/views/FillExam"),
                        name: "fill.exam",
                        props: true
                    },
                    {
                        path: "/completed-exams",
                        name: "completed.exams",
                        component: () => import("./views/FilledFormsList.vue")
                    },
                    {
                        path: "/exam-result/:id",
                        component: () => import("./views/ExamResult.vue"),
                        name: "exam.result",
                        props: true
                    },
                ]
            },
            {
                path: "/help",
                component: () => import("./views/Help.vue"),
                name: "help"
            },
        ]
    }
];
