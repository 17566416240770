import exams from "./exams";
import templates from "./templates";
import user_forms from "./user_forms";

/**
 * Vuex store accumulator
 *
 * Reexport to simplest use in main.js
 */

export default [
    {
        name: "exams",
        module: exams
    },
    {
        name: "templates",
        module: templates
    },
    {
        name: "user_forms",
        module: user_forms
    }
];

